<template>
<div class="wrapper">
  <h4>{{ titleName }}</h4>
  <el-form :model="form" ref="form" :rules="rules" label-width="100px">
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item  label="姓名" prop="staffName">
          <el-input v-model="form.staffName" placeholder="请输入姓名" auto-complete="off"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item  label="职位" prop="position">
          <el-input v-model="form.position" placeholder="请输入职位" auto-complete="off"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item  label="联系方式" prop="tel">
          <el-input v-model="form.tel" placeholder="请输入联系方式" auto-complete="off"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item  label="个人证书">
          <el-upload
          class="upload-demo"
          :action="uploadApi"
          :headers="headers"
          :on-success="handleAvatarSuccess"
          :on-remove="handleRemove"
          :file-list="fileList"
          :limit="5">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
        </el-upload>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24">
        <el-form-item style="text-align: center;" label-width="0">
          <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
          <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions
} from "vuex";
import api from '@/store/API/api'
export default {
  name: 'AddOrEdit',
  data() {
    return {
      headers:{ Authorization:sessionStorage.token },
      uploadApi: process.env.NODE_ENV === 'development' ?  '/api' + api.fileUpload  : process.env.VUE_APP_BASEURL + api.fileUpload,
      fileList:[],
      addimageList:[],
      titleName: '',
      form: {},
      tablePage: {
        totalResult: 0,
        currentPage: 0,
        pageSize: 10,
        align: "left",
        pageSizes: [10, 20, 50, 100, 200, 500, 1000],
        perfect: true
      },
	    plateNoId: '',
      rules: {
          name: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
          ],
        },
      sexList: [
        {
          value: '男',
          label: '男'
        },
        {
          value: '女',
          label: '女'
        }
      ]
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    },
	searchist() {
	      var list = [];
	      var _list = [];
	      var _this = this;
	      this.seqList.map(function (item) {
	        if (item.plateNo.indexOf(_this.plateNoId) != -1) {
	          //然后把当前json添加到list数组中
	          _list.push(item);
	          list = _list;
	        }
	      });
	      return list;
	    }
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions([
      "addorgStaff",
      "updateWorkPersonServe"
    ]),
  handleAvatarSuccess(res, file,fileList) {
      if(res.code == 200) {
        this.$message.success('上传成功')
        this.addimageList = fileList.map(item => {
          return {
            certImg:URL.createObjectURL(item.raw),
            certName:item.name,
            certTime:(new Date()).toLocaleDateString().split('/').join('-')
            }
        })
      }
  },
  handleRemove(file,fileList) {
    console.log(file,fileList);
    if (fileList.length>0) {
      this.addimageList = fileList.map(item => {
          return {
            certImg:URL.createObjectURL(item.raw),
            certName:item.name,
            certTime:(new Date()).toLocaleDateString().split('/').join('-')
            }
        })
    }else {
      this.addimageList = []
    }
  },
	focusEvent1() {
	  this.$refs.xDown1.showPanel();
	},
	//获取水泥车数据
	plateNoClick(item) {
	  this.form.plateNoId = item.id;
	  this.plateNoId = item.plateNo;
	  this.$refs.xDown1.hidePanel();
	},
	getLeaveTime(data) {
	  this.form.leaveTime = data;
	},
	getFactTime(data) {
	  this.form.factTime = data;
	},
    initView() {
      if (this.routeType == 'add') {
        this.titleName = '添加员工信息'
        this.form.organizationId = JSON.parse(this.$route.query.item)
      } else {
        this.titleName = '修改员工信息'
        this.itemData = JSON.parse(this.$route.query.item);
        //这里调用请求编辑时的详情接口渲染form表单数据
        this.form = {
          address: this.itemData.address,
          bedNumber: this.itemData.bedNumber,
          emptyBedNumber: this.itemData.emptyBedNumber,
          mainPerson: this.itemData.mainPerson,
          mainPersonCont: this.itemData.mainPersonCont,
          orgName: this.itemData.orgName,
          id: this.itemData.id
        }
      }
    },
    //区域选择
    selectArea(data) {
      this.form.teamName = '';
      this.teamByAreaList = '';
      this.getTeamListByArea(data); //获取队伍
    },
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {}
          params = this.form
          params.certificateList = this.addimageList
          let server = this.routeType == 'add' ? "addorgStaff" : "updateWorkPersonServe"
          this[server](params).then(res => {
            if (res.code == 200) {
              this.$XModal.message({
                message: this.routeType == 'add' ? '添加成功' : '修改成功',
                status: "success"
              });
              this.$router.go(-1)
            }
            this.loading = false;
          })
        } else {
          return false;
        }
      });
      
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .vxe-form {
    padding: 25px 0;
    overflow: auto;
  }
  .my-dropdown1 {
      height: 200px;
      overflow: auto;
      border-radius: 4px;
      background-color: #fff;
      border: 1px solid #dcdfe6;
	  padding: 0 8px;
      li {
        height: 32px;
        line-height: 32px;
        cursor: pointer;
      }
    }
}
</style>
